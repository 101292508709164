(() => {
  let language = document.documentElement.lang;
  const mainMenuSpace = document.getElementsByClassName("main-menu-space")[0];

  const buttonDownBanner = document.querySelector(
    ".banner .banner--down-button"
  );
  buttonDownBanner.addEventListener("click", function () {
    const ourServicesSection = document.querySelector(".our-services");
    window.scrollTo(
      0,
      ourServicesSection.offsetTop - mainMenuSpace.offsetHeight
    );
  });
})();
